import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { CtIcon } from './CtIcon';
const Container = styled.div`
  background: rgb(221 211 211 / 10%);
  border-radius: 7px;
  display: inline-flex;
  padding: 10px;
  justify-content: flex-end;
`;

const Link = styled.a`
  background: linear-gradient(315deg, #58424a 0, #e0003e 100%);
  display: flex;
  border-radius: 5px;
  padding: 5px 35px 5px 15px;

  &:hover {
    background: #e0003e !important;
  }
`;

const TopText = styled.div`
  font-size: 0.8em;
  font-weight: bold;
  color: #fff;
`;

const BottomText = styled.div`
  font-size: 0.8em;
  font-weight: bold;
  color: #f0df25;
  //color: #c4b550;
`;
export function Button(props) {
  const directBtn = useRef(null);
  const torrentBtn = useRef(null);

  useEffect(() => {
    directBtn.current.addEventListener('click', log);
    torrentBtn.current.addEventListener('click', log);
    function log() {
      fetch('/php/api/csdownloads/');
    }
    return () => {
      directBtn.current.removeEventListener('click', log);
      torrentBtn.current.removeEventListener('click', log);
    };
  }, []);

  return (
    <Container {...props}>
      <Link href='/csdownload-r/' target='_blank' className='mr-2' ref={directBtn}>
        <CtIcon />
        <div>
          <TopText>DOWNLOAD</TopText>
          <BottomText>Direct link</BottomText>
        </div>
      </Link>
      <Link href='/csdownload-r/?type=torrent' target='_blank' ref={torrentBtn}>
        <CtIcon />
        <div>
          <TopText>DOWNLOAD</TopText>
          <BottomText>Torrent</BottomText>
        </div>
      </Link>
    </Container>
  );
}
