import React from 'react';
import { withRouter } from 'next/router';
import LatestPosts from 'areas/forum/components/LatestPosts';
import CSDownloadPanel from 'areas/cs-download/CSDownloadPanel';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import { DiscordPanel } from 'areas/discord/components';
import axios from 'axios';
import styled from 'styled-components';
import { Box } from 'ui';
// import { Row, Col } from 'antd';
// import AdSense from 'react-adsense';
import { Row, Col, Button, notification } from 'antd';
import { ServerList } from 'ui';
const FaceBookPanel = dynamic(() => import('ui/FaceBookPanel'), {
  ssr: false
});
// const AdSencePanel = dynamic(() => import('ui/AdSencePanel'), {
//   ssr: false
// });

const DrawingBoard = dynamic(() => import('areas/drawingBoard/components/Container'), {
  ssr: false
});

import { withTranslation, translateNodeBBTemplate } from '_core/i18n';
import { buildCategoryUrl } from '_core/utils';
import Link from 'next/link';

const HelperButton = styled.a`
  display: block;
  text-align: center;
  background: linear-gradient(315deg, #58424a 0, #e0003e 100%);
  color: #fff !important;
  padding: 10px;
  border: 1px solid #2d73c0;
  border-radius: 2px;
`;

//http://localhost:4567/?register=%5B%5Bregister%3Aregistration-added-to-queue%5D%5D
class Index extends React.Component {
  static async getInitialProps({ query, req }) {
    const isServer = !!req;

    if (!isServer) {
      const response = await axios.get('/api/recent');
      query.recent = response.data;
    }

    return {
      recent: query.recent,
      namespacesRequired: ['common']
    };
  }

  componentDidMount() {
    const { t } = this.props;
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.register) {
      console.log('params', params);
      const key = `open${Date.now()}`;

      const btn = (
        <Button type='primary' size='small' onClick={() => notification.close(key)}>
          OK
        </Button>
      );

      // 'Your registration has been added to the approval queue. You will receive an email when it is accepted by an administrator.'
      // [[register:registration-added-to-queue]]
      notification.open({
        message: 'Registration',
        description: translateNodeBBTemplate(params.register, t),
        btn,
        key,
        duration: 0
      });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div className='container mt-3'>
        <Head>
          <title>{translateNodeBBTemplate('[[pages:home]]', t)}</title>
          <meta
            name='description'
            content='Download CS 1.6 game for free, The original Counter-Strike 1.6 game contains all the latest updates and patches. Supports PC versions of Windows XP, Windows 7, Windows 8, Windows 10.'
          />
        </Head>
        <DrawingBoard />

        <Row gutter={24}>
          <Col xs={{ span: 24 }} lg={{ span: 23 }} className='mb-3'>
            <CSDownloadPanel />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={{ span: 24 }} lg={{ span: 8 }} className='mb-3'>
            <ServerList />
            {/* <AdSencePanel slotId='8875325074' /> */}
            {/* <div className='mt-3' />
            <AdSense.Google
              client='ca-pub-3219722052726085'
              slot='4021017786'
              style={{ display: 'block' }}
              format='auto'
              responsive='true'
              layoutKey='-gw-1+2a-9x+5c'
            /> */}
            <DiscordPanel />
            <FaceBookPanel />
            <Box headerText='Partners' className='mt-3'>
              <a href='https://www.wargods.ro' target='_blank'>
                <img
                  src='/static/images/wargods/400x60.jpg'
                  border='0'
                  alt='WarGods Community Servers'
                  className='w-100'
                />
              </a>
            </Box>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 15 }}>
            {/* <LatestPosts topics={this.props.recent.topics} className='mt-3' /> */}
            <LatestPosts topics={this.props.recent.topics} />
            {this.renderHelpButtons()}
          </Col>
        </Row>
      </div>
    );
  }

  renderHelpButtons() {
    const { t } = this.props;
    const ssDemo = buildCategoryUrl(1, '74/demo-ss-ir-wg-failai');
    const unban = buildCategoryUrl(1, '67/unban');
    return (
      <Row gutter={24} className='mt-3'>
        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
          <Link href={ssDemo.path} as={ssDemo.url} passHref>
            <HelperButton>{t('ss-and-demo')}</HelperButton>
          </Link>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
          <Link href={{ pathname: '/lost_priv' }} as='/lost-priv' passHref>
            <HelperButton href='/lost-priv'>{t('recover-privilegies')}</HelperButton>
          </Link>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
          <Link href={unban.path} as={unban.url} passHref>
            <HelperButton>{t('unban-request')}</HelperButton>
          </Link>
        </Col>
      </Row>
    );
  }
}

export default withRouter(withTranslation('common')(Index));
