import React from 'react';
import { Box } from 'ui';
import { Button } from './ButtonRed';
import { Row, Col } from 'antd';
import { Paragraph, H1, Markup } from './styles';
import { useSelector } from 'react-redux';
import { getIsLoggedIn } from 'areas/session/selectors';

export default function CSDownloadPanel() {
  const loggedIn = useSelector(getIsLoggedIn);
  if (loggedIn) {
    return null;
  }
  return (
    <Box className='p-3'>
      <H1>Counter-Strike 1.6 Download</H1>
      <Row gutter={24}>
        <Col xs={{ span: 24 }} lg={{ span: 8 }} style={{ paddingRight: '0px' }}>
          <img
            src='/static/images/cs-download/header.jpg'
            alt='cs 1.6 download'
            title='cs 1.6 download'
            className='img-responsive'
          />
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 15 }} className='pl-3'>
          <Paragraph>
            Download CS 1.6 game for free, The original <Markup>Counter-Strike 1.6</Markup> game
            contains all the latest updates and patches. Supports PC versions of Windows XP, Windows
            7, Windows 8, Windows 10. <Markup>CS 1.6 Game</Markup> introduced in 1999 was the first
            person shooter game. They developed by valve corporation. Initially, they developed and
            most famous day by day. <Markup>Counter-Strike 1.6</Markup> also called as half-life
            modification.
          </Paragraph>
          <Button />
        </Col>
      </Row>
    </Box>
  );
}
