import React from 'react';
import styled from 'styled-components';

const Picture = styled.picture`
  vertical-align: middle;
  & img {
    width: 40px;
    padding-right: 6px;
    vertical-align: middle;
  }
`;
export function CtIcon() {
  return (
    <Picture width='40' height='40'>
      <source srcSet='/static/images/cs-download/csct-40.webp' type='image/webp' />
      <img
        src='/static/images/cs-download/csct-40.png'
        title='Counter-Strike 1.6'
        width='40'
        height='40'
        alt='CS 1.6 Download'
      />
    </Picture>
  );
}
