import styled from 'styled-components';
import { fontColor, textMarkup } from '_theme';

export const Paragraph = styled.p`
  text-align: justify;
  color: ${fontColor};
`;
export const Image = styled.img`
  width: 100%;
  height: 200px;
`;

export const ImageBorder = styled.div`
  padding: 4px;
  line-height: 1.42857;
  background-color: rgba(41, 31, 31, 0.1);
  display: inline-block;
  max-width: 100%;
  height: auto;
`;

export const TagLink = styled.a`
  color: #698fdd;
`;
export const H1 = styled.h1`
  color: ${fontColor};
`;

export const H3 = styled.h3`
  color: ${fontColor};
`;

export const Markup = styled.span`
  color: ${textMarkup};
`;

export const Container = styled.div`
  color: ${fontColor};
`;
